.App {
  text-align: center;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
  height: 10px; /* Height of the horizontal scrollbar */
}

/* Track (background of the scrollbar) */
::-webkit-scrollbar-track {
  background: transparent; /* Light background */
  border-radius: 4px; /* Rounded corners */
}

/* Handle (the draggable scrolling element) */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.24); /* Darker color for contrast */
  border-radius: 4px; /* Rounded corners */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5); /* Slightly darker on hover for feedback */
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.custom-collapse .ant-collapse-header {
  color: #d1d5db !important; /* Ensure this overrides existing styles */
}

.custom-collapse .ant-collapse-header:hover {
  color: #d1d5db !important; /* Ensure hover state color is applied */
}

/* Optional: ensure the content background is transparent if needed */
.custom-collapse .ant-collapse-content {
  background: transparent !important;
}

.custom-collapse .ant-collapse-content-box {
  background: transparent !important;
}

.custom-font-size .react-grid-cell {
  font-size: 8px !important;
}

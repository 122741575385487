@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  img {
    @apply inline-block;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

body {
  font-family: "WantedSans", sans-serif;
}

@font-face {
  font-family: "WantedSans";
  src: url("https://s3.mailtocell.com/landing/fonts/WantedSans-Regular.woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "WantedSans";
  src: url("https://s3.mailtocell.com/landing/fonts/WantedSans-Medium.woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "WantedSans";
  src: url("https://s3.mailtocell.com/landing/fonts/WantedSans-SemiBold.woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "WantedSans";
  src: url("https://s3.mailtocell.com/landing/fonts/WantedSans-Bold.woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

.menu {
    width: 15rem;
    padding: 0.5rem 0;
    border-radius: 0.375rem;
    box-shadow: 0px 0px 1px rgba(62, 65, 86, 0.7), 0px 6px 12px rgba(62, 65, 86, 0.35);
    display: flex;
    flex-direction: column;
    background-color: white;
}

.menu .item, .checkboxGroup > label {
    padding: 6px 8px;
    color: rgba(0, 0, 0, 0.7);
    transition: background-color 100ms;
    cursor: pointer;
    justify-content: start;
}

.menu .item:hover, .checkboxGroup > label:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.9);
}
